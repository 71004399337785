import React from "react"
import { Carousel } from "react-responsive-carousel"

import "./carousel.css"

const CustomCarousel = ({ ...props }) => {
  return (
    <Carousel infiniteLoop autoPlay interval={5000} showStatus={false} showThumbs={false}>
      {props.children}
    </Carousel>
  )
}

export default CustomCarousel
