import React from "react"
import {graphql} from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/layout"
import CustomCarousel from "../components/carousel"
import BusinessCard from "../components/businessCard"
import {BackgroundScrollButton} from "../components/buttons"

import "./index.css"

export default function Home({data}) {
    const bannerShadow = data.bannerShadow.childImageSharp.fluid
    const instagramData = data.instagramData.childHomeJson
    const proceduresData = data.proceduresData.edges
    const blogData = data.blogData.edges
    let temporaryInstagramChunk
    let instagramSection = []
    for (let i = 0; i < instagramData.users.length; i += instagramData.usersPerSlide) {
        temporaryInstagramChunk = instagramData.users.slice(i, i + instagramData.usersPerSlide)
        instagramSection.push(
            <div key={i} className='instagram-slide'>
                {temporaryInstagramChunk.map((user, j) => {
                    return (
                        <a key={j} href={`http://instagram.com/${user.userName}`} target="_blank noreferrer"
                           aria-label='instagram account'>
                            <Image fluid={user.image.childImageSharp.fluid}/>
                            <div style={{marginTop: "15px", textAlign: "left"}}>@{user.userName}</div>
                        </a>
                    )
                })}
            </div>
        )
    }
    const proceduresSection = proceduresData.map((edge, index) => {
        const procedures = edge.node.childProceduresJson
        const proceduresList = procedures.pList.filter(p => p.show_in_home_page)
        const drawnProcedure = proceduresList[Math.floor(Math.random() * proceduresList.length)];
        return drawnProcedure ? (
            <BusinessCard
                key={index}
                image={procedures.carousel_image ? procedures.carousel_image.childImageSharp.fluid : procedures.banner.childImageSharp.fluid}
                shadow={bannerShadow}
                title={drawnProcedure.title}
                shortDetails={procedures.title}
                details={drawnProcedure.details.content[0].description}
                button={{
                    text: 'Poznaj ofertę',
                    url: `/procedures/${procedures.slug}/${drawnProcedure.slug}`
                }}
            />
        ) : null
    }).filter(e => e != null)

    const blogSection = blogData.map((edge, index) => {
        const article = edge.node.childBlogJson
        return (
          <BusinessCard
            image={article.main_image.childImageSharp.fluid}
            shadow={bannerShadow}
            title={article.title}
            shortDetails={article.veryShortDescription}
            details={article.shortDescription}
            button={{
                text: 'Czytaj',
                url: `/blog/${article.slug}`
            }}
          />
        )
    }).filter(e => e != null)
    return (
        <Layout>
            <div className='background-image'>
                <video className='home-banner' width="100%" poster='/video_poster_default.jpg' autoPlay muted loop>
                    <source src='/logo_movie.mp4' type="video/mp4"/>
                    Twoja przeglądarka nie mogła wyświetlić tego filmu
                </video>
                <Image
                    fluid={bannerShadow}
                    className='background-rounded-shadow'
                    style={{position: "absolute"}}
                    loading="eager"
                />
                <BackgroundScrollButton elementId='content'/>
            </div>
            <div id='content' className='home-container'>
                <div className='home-section'>
                    <Image
                        fluid={bannerShadow}
                        className='background-rounded-shadow'
                        style={{position: "absolute"}}
                        loading="eager"
                    />
                    <h1>Zabiegi</h1>
                    <CustomCarousel>
                        {proceduresSection}
                    </CustomCarousel>
                    <h1>Blog</h1>
                    <CustomCarousel>
                        {blogSection}
                    </CustomCarousel>
                </div>
                <div className='home-instagram-section'>
                    <h1>#Instagram</h1>
                    <CustomCarousel>
                        {instagramSection}
                    </CustomCarousel>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
  query {
    proceduresData: allFile(filter: {relativeDirectory: {eq: "procedures"}}) {
      edges {
        node {
          childProceduresJson {
            slug
            title
            banner {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            carousel_image {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            pList {
              slug
              title
              show_in_home_page
              details {
                content {
                  description
                }
              }
            }
          }
        }
      }
    }
    blogData: allFile(filter: {relativeDirectory: {eq: "blog"}}) {
      edges {
        node {
          childBlogJson {
            slug
            title
            creation_date
            veryShortDescription
            shortDescription
            main_image {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    instagramData: file(relativePath: {eq: "home/instagram.json"}) {
      childHomeJson {
        usersPerSlide
        users {
          userName
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    bannerHeader: file(relativePath: {eq: "home/images/banner_header.jpeg"}) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bannerShadow: file(relativePath: {eq: "images/banner_header_shadow.png"}) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    instagramImages: allFile(filter: {relativeDirectory: {eq: "home/images/instagram"}}) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
